import React from 'react';
import Image from 'gatsby-image';
import { Link, graphql, useStaticQuery } from 'gatsby';
// import responsiveDesign from '../images/responsive-web-design.jpg';
// import analyticsImg from '../images/analytics-stock-img.jpg';

const query = graphql`
  {
    first: file(relativePath: {eq: "responsive-web-design.jpg"}) {
      childImageSharp {
        fluid1: fluid(quality: 100) {
			...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    second: file(relativePath: {eq: "analytics-stock-img.jpg"}) {
      childImageSharp {
        fluid2: fluid(quality: 100) {
			...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const ServicesSection = () => {
	const { first: { childImageSharp: { fluid1 } }, second: { childImageSharp: { fluid2 } } } = useStaticQuery(query);

	return (
		<section className="mb-12 pb-12">
			<h2 className="font-bold text-center text-wtblue my-2 text-2xl lg:text-3xl xl:text-4xl xl:my-4">
				Services Offered
			</h2>
			<div className="container mx-auto flex flex-col md:flex-row items-center">
				{/* Left Colum */}
				<div className="flex flex-col w-full justify-center items-start pt-2 pb-6 px-6 text-center md:text-left md:pt-12 lg:w-1/2">
					<div className="w-full flex justify-center">
						<h3 className="w-full text-center font-bold text-gray-900 mb-2 text-xl md:text-left md:text-lg lg:text-2xl xl:text-3xl xl:mb-4">
							Website Design and Development
						</h3>
					</div>
					<div className="w-full justify-center md:justify-start">
						{/* <img
							src={responsiveDesign}
							alt="Responsive Website Design"
							className="mb-4 h-auto max-w-full xl:max-w-xl xl:h-img"
						/> */}
						<Image fluid={fluid1} alt="Mobile Responsive Website Design" className="mb-4 h-auto max-w-full xl:max-w-xl xl:h-img" />
					</div>

					<p className="text-sm text-center text-gray-900 mb-2 md:text-left lg:text-xl lg:mb-2 xl:mb-4">
						I create blazing fast, custom websites specifically designed to create the branding message you
						desire and boost your company’s sales.
					</p>
				</div>
				{/* Right Column */}
				<div className="flex flex-col w-full justify-center items-start pt-2 pb-6 px-6 text-center md:text-left md:pt-12 lg:w-1/2">
					<div className="w-full flex justify-content">
						<h3 className="w-full text-center font-bold text-gray-900 mb-2 text-xl md:text-left md:text-lg lg:text-2xl xl:text-3xl xl:mb-4">
							Business Apps and Data Analytics
						</h3>
					</div>
					<div className="w-full justify-center md:justify-start">
						{/* <img
							src={analyticsImg}
							alt="Data Analytics"
							className="mb-4 h-auto max-w-full xl:max-w-xl xl:h-img"
						/> */}
						<Image fluid={fluid2} alt="Data Analytics" className="mb-4 h-auto max-w-full xl:max-w-xl xl:h-img" />
					</div>

					<p className="text-sm text-center text-gray-900 mb-2 md:text-left lg:text-xl lg:mb-2 xl:mb-4">
						I build web applications, create data visualizations, and find data driven solutions that will
						make your life easier and help you make smarter decisions.
					</p>
				</div>
			</div>
			<div className="flex justify-center">
				<Link
					to="/services"
					className="inline-block bg-wtblue text-center text-white text-md font-bold py-2 px-4 rounded w-48 transition duration-500 ease-in-out hover:bg-wtbluehover xl:text-xl xl:w-56"
				>
					More Details
				</Link>
			</div>
		</section>
	);
};

export default ServicesSection;
