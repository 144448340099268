import React from 'react';
import { Link } from 'gatsby';

const ContactSection = () => {
	return (
		<section className="mb-12 pb-12">
			<h2 className="font-bold text-center text-wtblue my-2 text-2xl lg:text-3xl xl:text-4xl xl:my-4">
				Ready to Get Started On a Project?
			</h2>
			<div className="flex justify-center">
				<Link
					to="/contact"
					className="inline-block bg-wtblue text-center text-white text-md font-bold py-2 px-4 rounded w-48 transition duration-500 ease-in-out hover:bg-wtbluehover xl:text-xl xl:w-56"
				>
					Get a Free Quote
				</Link>
			</div>
		</section>
	);
};

export default ContactSection;
