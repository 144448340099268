import React from 'react';
import Image from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
// import headshot from '../images/headshot.jpg';

const query = graphql`
  {
    file(relativePath: {eq: "headshot.jpg"}) {
      childImageSharp {
        fluid(quality: 100) {
			...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`


const AboutSection = () => {
	const { file: { childImageSharp: { fluid } } } = useStaticQuery(query);
	return (
		<section className="mb-12 pb-12">
			<h2 className="font-bold text-center text-wtblue my-2 text-2xl lg:text-3xl xl:text-4xl xl:my-4">
				About Me
			</h2>

			<div className="container mx-auto flex flex-col md:flex-row items-center">
				{/* Left Section */}
				<div className="flex justify-center md:w-2/3">
					<p className="text-sm text-center text-gray-900 mb-2 px-6 pb-2 md:text-left md:pl-6 md:pr-12 md:pb-0 lg:pr-16 lg:text-xl">
						Hey, I’m Colin! After attending college and starting my professional career I realized that my
						true passion was to help people utilize technology and data to grow their businesses. I started
						Willer Technologies to give me that opportunity and help me fulfill my dream of running my own
						business. Now I get to help clients propel their businesses by developing custom websites, web
						applications, data visualizations, and other technology solutions. Please feel free to get in
						contact to learn more about what I can do to help advance your business.
					</p>
				</div>
				{/* Right Section */}
				<div className="justify-center w-10/12 md:w-1/3">
					{/* <img
						src={headshot}
						alt="Colin Willer Headshot Image"
						className="rounded-full h-auto max-w-full xl:max-w-sm"
					/> */}
					<Image fluid={fluid} alt="Colin Willer Headshot Image" className="rounded-full h-auto max-w-full xl:max-w-sm" />
				</div>
			</div>
		</section>
	);
};
export default AboutSection;
