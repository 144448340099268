import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import ServicesSection from '../components/services-section';
import FeaturedProjects from '../components/featured-projects';
import AboutSection from '../components/about-section';
import ContactSection from '../components/contact-section';

export const query = graphql`
	{
		allStrapiProjects(sort: { fields: updated_at, order: DESC }, filter: { FeaturedProject: { eq: true } }) {
			nodes {
				id
				Category
				Description
				FeaturedProject
				Name
				WebsiteURL
				updated_at
				Image {
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		}
	}
`;

const IndexPage = ({ data }) => {
	const { allStrapiProjects: { nodes: projects } } = data;

	return (
		<Layout>
			<SEO keywords={[ `Website Development`, `Website Design`, `Web Application`, `Data Analytics`, `Data Visualization`, `Freelance`, `Colin Willer` ]} title="Home" />
			<Hero />
			<ServicesSection />
			<FeaturedProjects projects={projects} />
			<AboutSection />
			<ContactSection />
		</Layout>
	);
};

export default IndexPage;
