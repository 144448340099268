import React from 'react';
import Image from 'gatsby-image';
import { Link, graphql, useStaticQuery  } from 'gatsby';
// import typingImg from '../images/stock-typing-img.jpg';

const query = graphql`
  {
    file(relativePath: {eq: "stock-typing-img.jpg"}) {
      childImageSharp {
        fluid(quality: 100) {
			...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
const Hero = () => {
	const { file: { childImageSharp: { fluid } } } = useStaticQuery(query);
	return (
		<section className="container mx-auto flex flex-col md:flex-row items-center lg:mb-12">
			{/* Left Column */}
			<div className="flex flex-col w-full justify-center items-start pt-2 pb-8 px-6 text-center md:text-left md:pt-12 lg:w-1/2 md:pb-24 lg:pb-24">
				<h1 className="font-bold text-wtblue my-2 text-3xl lg:text-4xl xl:text-6xl xl:my-4">
					Digital Solutions <span className="text-gray-900">for Your Business</span>
				</h1>
				<p className="text-sm text-gray-900 mb-2 lg:text-xl lg:mb-2 xl:mb-4">
					Technology solutions to improve your online exposure, take your business processes to the next
					level, and leverage your data to make smarter business decisions
				</p>
				<div className="inline-block">
					<Link
						to="/services"
						className="inline-block bg-wtblue text-center text-white text-md font-bold py-2 px-4 rounded w-48 transition duration-500 ease-in-out hover:bg-wtbluehover xl:text-xl xl:w-56"
					>
						Services Offered
					</Link>
					<Link
						to="/contact"
						className="inline-block bg-transparent border border-wtblue text-center text-wtblue text-md font-bold py-2 px-4 w-48 rounded mt-2 xl:mt-0 lg:ml-4 transition duration-500 ease-in-out hover:bg-wtbluehover hover:text-white xl:text-xl xl:w-56"
					>
						Get a Free Quote
					</Link>
				</div>
			</div>
			{/* Right Column */}
			<div className="w-full lg:w-1/2 lg:py-6 hidden md:block">
				{/* <img src={typingImg} alt="Colin Willer Headshot Image" /> */}
				<Image fluid={fluid} className="w-full hidden md:block" />
			</div>
		</section>
	);
};

export default Hero;
